export const ROUTE_PATHS = {
  HOME: "/",
  BOOKS: "/books",
  BOOKS_BOOK: "/books/:id",
  ABOUT: "/about",
  ADMIN: "/admin",
  ADMIN_CARROUSEL: "/admin/manage-carrousell",
  ADMIN_BOOKS_CREATE: "/admin/books/create",
  ADMIN_BOOKS_EDIT: "/admin/books/edit/:id",
  ADMIN_BOOKS_SETTINGS: "/admin/books/settings/:id",
  ADMIN_COLLECTION: "/admin/manage-collections",
  ADMIN_COLLECTION_CREATE: "/admin/manage-carrousell/create",
  ADMIN_COLLECTIONS_EDIT: "/admin/collections/edit/:id",
  LOGIN: "/login",
  CLIENT_MANAGEMENT: "/client-management",
  CART: "/cart",
  CHECKOUT: "/checkout",
  BUY_SUCCESS: "/buy-success",
  PRIVACY_POLICY: "/privacy-policy",
  POLICIES: "/policies",
};
