export const content = {
  en: {
    translations: {
      cookiePolicy: {
        title: "Cookie Policy",
        acceptTerms: "Accept Cookies",
        mainText:
          "This website obtains information exclusively to increase the user experience. Visit our ",

        secondText: " for more information",
        cookiePolicy: "Cookie Policy",
      },
      forms: {
        checkout: {
          email: "E-mail",
          phone: "Phone",
          name: "Name",
          country: "Country",
        },
        required: "Required",
        fileSize: "The file must be under ",
        email: "Must be a valid e-mail",
        password:
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
      },
      topBar: {
        memory: "Memory",
        hope: "Hope",
        audacity: "Audacity",
      },
      menuBar: {
        home: "Home",
        books: "Books",
        about: "About",
        contacts: "Contacts",
        account: "My Account",
        cart: "Cart",
        language: "Language",
        instagram: "Follow us",
        userPopover: {
          user: "Manage User",
          logout: "Logout",
          admin: "Admin",
          login: "Login",
        },
      },
      footer: {
        second: {
          second: "Privacy Policy",
          third: "@ Àbolina 2023 | All rights reserved",
        },
        third: {
          first: "Follow us",
          second: "Este site está de acordo com o novo acordo Ortográfico",
        },
        forth: {
          button: "Talk with us",
        },
      },
      adminSideBar: {
        submitBook: "Manage Books",
        manageCarroussel: "Manage Carroussell",
        back: "Back",
      },
      modules: {
        checkout: {
          resumePurchase: "Resume Purchase",
          cartItems: "Items on Cart",
          itemsTotal: "Value from Items:",
          discountTotal: "Value from Discount:",
          total: "Total Value:",
          contactDetails: "Contact Details",
          invoiceDetails: "Invoice Details",
          paymentMethods: "Payment Methods",
          backCart: "Back to Cart",
          checkout: "Checkout",
          books: "Book(s)",
          forOffer: "for offer",
        },
        cart: {
          title: "Shopping Cart",
          title2: "Checkout",
          discountCuppon: "Discount Cuppon",
          applyDiscount: "Apply",
          total: "Total:",
          finalize: "Proceed to checkout",
          table: {
            product: "Product",
            price: "Price",
            quantity: "Quantity",
            subtotal: "Subtotal",
            offer: "offer",
            offerTooltip: "Will be generated one or more offer codes",
            offerThisBook: "It is only to offer",
            offerCode: "(Without a copy for yourself)",
            readOnApp: "Read on App",
            readOnAppTooltip: "Available only on App",
          },
        },
        login: {
          title: "My Account",
          startSession: "Start Session",
          register: "Create Account",
          google: "Login with Google",
          facebook: "Login with Facebook",
          or: "or",
          email: "E-mail*",
          password: "Password*",
          confirmPassword: "Confirm Password*",
          name: "Name*",
          createAccount: "Create Account",
          acceptTerms: "Accept terms and conditions",
          retrievePassword: "Retrieve your password",
          recoverPwdInfo:
            "(You will receive a link by email to create a new password)",
        },
        home: {
          mainTextBtn: "READ MORE",
          contacts: {
            contactsTitle: "Contact Us",
            form: {
              name: "Name",
              email: "E-mail",
              subject: "Subject",
              description: "Message",
              send: "SEND",
            },
          },
        },
        about: {
          mainText:
            "ÀBolina was conceived to tell people stories. Stories that cherish memory. Stories that give us hope. Stories that inspire us to be audacious, to have courage and resilience. The creative books we publish are high quality in pictures and content, and are aimed at reinforcing a desire to strive for a greater good in children and teenagers.\n\n Above all, we hope that our stories meet the needs of families educating their children to be confident, balanced individuals, capable of making responsible choices, able to think for themselves, perceive where the truth lies and to act with kindness.",
          secondTitle: "WHY MEMORY?",
          second:
            "Memory is truly a treasure of humankind, passing through time, enriching the thoughts, values and ideas of each generation.",
          thirdTitle: "WHY HOPE?",
          third:
            "Hope is a cradle of life and creativity, driving healthy development whether it be of the mind, body or spirit. Nurtured by hope, the human being becomes open to good, to the beautiful and to truth.",
          forthTitle: "WHY AUDACITY?",
          forth:
            "Because we understand that the edifice of our existence is built by embracing challenges, overcoming our difficulties and fears, to shape a meaningful project for our lives.",
        },
        books: {
          collections: "Collections",
          book: {
            bookTitle: "Title",
            backCollection: "Back to Collection",
            backBooks: "Back to Books",
            text: "Text:",
            price: "Price:",
            design: "Design:",
            translation: "Translation:",
            pages: "Number of pages:",
            language: "Language:",
            caracteristics: "Caracteristics:",
            weight: "Weight:",
            size: "Size",
            collectionBrowser: "More from Collection",
            bookBrowser: "VIEW THE\n BOOK",
            peakDigital: "LOOK\n DIGITAL",
            new: "New",
            soon: "Soon",
            title: "THE HISTORY",
            collection: "THE COLLECTION",
            writer: "WRITER",
            designer: "DESIGNER",
            translator: "TRANSLATOR",
            addToCart: "Add to Cart",
            papper: "Papper",
            digital: "Digital",
            availableStores: "Available in Stores",
            loading: "Looking for your book",
          },
          viewBook: {
            page: "PAGES",
          },
        },
        admin: {
          manageBooks: {
            createButton: "Add new book",
            submitBook: {
              breadCrumbs: "Manage Books - Submit Book",
              submitTitle: "Submit Book",
              title: "Title",
              pages: "Number Pages",
              coverPage: "Cover Page",
              content: "Content",
              author: "Author",
              authorResume: "Author Resume",
              designer: "Designer",
              designerResume: "Designer Resume",
              translatorResume: "Translator Resume",
              translator: "Translator",
              language: "Language",
              weight: "Weight",
              size: "Size",
              resume: "Resume",
              price: "Price",
            },
            settingsBook: {
              breadCrumbs: "Manage Books - Settings Book",
            },
            tableList: {
              title: "Title Book",
              createdDate: "Date",
              newBook: {
                title: "New Book?",
                accept: "Yes",
                deny: "Cancel",
                confirmationDescription:
                  "Are you sure you want to change the status of this book?",
                confirmationTitle: "Change status of new Book",
                tooltip: "New Book status",
              },
              delete: {
                title: "Delete",
                accept: "Yes",
                deny: "Cancel",
                confirmationDescription:
                  "Are you sure you want to delete this book?",
                confirmationTitle: "Delete Book",
                tooltip: "Delete book",
              },
            },
          },

          manageCarroussell: {
            submitTitle: "Manage Carroussell",
            imagesKeep: "Images to keep",
            imagesDelete: "Images to Delete",
            tableList: {
              image: "image",
            },
          },
        },
        clientManagement: {
          title: "Client Management",
          generalSettings: "My settings",
          invoiceSettings: "Invoice Settings",
          couponsSettings: "Offer Codes",
          general: {
            name: "User Name",
            edit: "Edit",
            submit: "Save Settings",
            changePassword: "Change here your password",
            changePasswordTitle: "Change Password",
            confirmChangePassword: "Confirm",
            changePasswordHelperText: "An email will be sent to",
            changePasswordHelperText2:
              " to recover the password follow the instructions on the email",
          },
          invoice: {
            name: "Name",
            surname: "Surname",
            country: "Country",
            address: "Address",
            postalCode: "Postal Code",
            city: "City",
            taxId: "Tax Id",
          },
          coupons: {
            title: "Coupons Available",
            tooltipCopy: "Content copied to the clipboard",
          },
        },
        buySuccess: {
          title: "Your buying process was successfull!",
          text: "Good reading! You will receive by e-mail any existent offer code",
        },
      },
      notifications: {
        success: {
          newBook: "Book added with success",
          updateCarroussell: "The carroussell was updated",
          newCarrousselImage: "New image was added to the carroussell",
          newBookStatusChanged: "Book status changed",
          newUser: "You were registered with success",
          newUserEmailPassword: "Please visit your email to verify your e-mail",
          loginUser: "You are login",
          addedCart: "Book added to cart",
          recoverPassword: "An e-mail was sent to recover the password",
          successBuy: "Items bought with success",
          logout: "You are now Logout",
          userSettings: "The user settings were updated",
        },
        fail: {
          newBook: "Somethng went wrong, the book was not added",
          updateCarroussell: "The carroussell was not updated",
          newCarrousselImage: "New image failed to be added to the carroussell",
          newBookStatusChanged: "Book status failed to change",
          newUser: "You were not registered this time",
          emailInUseCreateAccount: "This email is already in use",
          loginUser: "Something went wrong",
          userSettings:
            "The user settings were not updated, if the problem continues please contact our serviçes",
          recoverPassword: "There was a problem recovering the password",
          emailNotVerified:
            "Please verify first your email to login with your account",
        },
      },
      popups: {
        readOnApp: {
          title: "Read on App",
          first: "Available on App",
          second: "My library Àbolina",
          third: "More information",
          forth: "here",
        },
        offer: {
          title: "Offer",
          first: "An offer code will be generated for each book offered",
        },
      },
      constants: {
        languages: {
          portuguese: "Portuguese",
          english: "English",
        },
      },
    },
  },
};
